import React from 'react'
import ReelsComp from './ReelsComp';


const Feed = () => {

  return (
    <div>
        <ReelsComp />
    </div>
  )
}

export default Feed