import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
// import { getArticlesAPI, updateArticleAPI } from "../action";
// import PostalModal from "./PostalModal";

const Container = styled.div`
	grid-area: main;
`;

const CommonBox = styled.div`
	text-align: center;
	overflow: hidden;
	margin-bottom: 8px;
	background-color: #fff;
	border-radius: 5px;
	position: relative;
	border: none;
	box-shadow: 0 0 0 1px rgb(0 0 0 / 15%), 0 0 0 rgb(0 0 0 / 20%);
`;

const ShareBox = styled(CommonBox)`
	display: flex;
	flex-direction: column;
	margin: 0 0 8px;
	color: #958b7b;
	div {
		button {
			outline: none;
			color: rgba(0, 0, 0, 0.6);
			font-size: 14px;
			line-height: 1.5;
			min-height: 48px;
			display: flex;
			align-items: center;
			border: none;
			background-color: transparent;
			font-weight: 600;
		}
		&:first-child {
			display: flex;
			align-items: center;
			padding: 8px 16px;
			img {
				width: 48px;
				border-radius: 50%;
				margin-right: 8px;
			}
			button {
				margin: 4px 0;
				flex-grow: 1;
				padding-left: 16px;
				border: 1px solid rgba(0, 0, 0, 0.15);
				border-radius: 35px;
				text-align: left;
			}
		}
		&:nth-child(2) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			padding-bottom: 4px;
			button {
				img {
					margin: 0 4px 0 -2px;
				}
			}
		}
	}
`;

const Article = styled(CommonBox)`
	padding: 0;
	margin: 0 0 8px;
	overflow: visible;
`;

const SharedActor = styled.div`
	padding-right: 40px;
	flex-wrap: nowrap;
	padding: 12px 16px 0;
	margin-bottom: 8px;
	display: flex;
	align-items: center;
	a {
		margin-right: 12px;
		flex-grow: 1;
		overflow: hidden;
		display: flex;
		img {
			width: 48px;
			height: 48px;
			border-radius: 50%;
		}
		& > div {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			flex-basis: 0;
			margin-left: 8px;
			overflow: hidden;
			span {
				text-align: left;
				&:first-child {
					font-size: 14px;
					font-weight: 700;
					color: #000;
				}
				&:nth-child(n + 2) {
					font-size: 12px;
					color: rgba(0, 0, 0, 0.6);
				}
			}
		}
	}
	button {
		position: absolute;
		top: 0;
		right: 12px;
		border: none;
		outline: none;
		background: transparent;
	}
`;

const Description = styled.div`
	padding: 0 16px;
	overflow: hidden;
	font-size: 14px;
	text-align: left;
`;

const SharedImage = styled.div`
	margin: 8px 16px 0;
	background-color: #f9fafb;
	img {
		width: 100%;
		height: 100%;
	}
`;

const SocialCount = styled.ul`
	line-height: 1.3;
	display: flex;
	align-items: flex-start;
	overflow: auto;
	margin: 0 16px;
	padding: 8px 0;
	border-bottom: 1px solid #e9efdf;
	color: rgba(0, 0, 0, 0.6);
	list-style: none;
	li {
		margin-right: 5px;
		font-size: 12px;
		button {
			display: flex;
			border: none;
			color: rgba(0, 0, 0, 0.6);
			background: transparent;
			span {
				padding-left: 5px;
			}
		}
	}
`;

const SocialActions = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 4px 12px;
	min-height: 40px;
	padding-bottom: 5px;
	button {
		display: inline-flex;
		align-items: center;
		padding: 8px;
		border: none;
		background: transparent;
		span {
			margin-left: 4px;
			color: rgba(0, 0, 0, 0.6);
			font-size: 14px;
		}
	}
	button.active {
		span {
			color: #0a66c2;
			font-weight: 600;
		}
		svg {
			fill: #0a66c2;
		}
	}
`;

// const Content = styled.div`
// 	text-align: center;
// 	& > img {
// 		width: 30px;
// 	}
// `;

const Content = () => {
	const articles = [
		{
		  actor: {
			image: 'https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
			title: 'John Doe',
			description: 'Software Engineer',
			date: new Date(),
		  },
		  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
		  sharedImg: 'https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
		  likes: { count: 10, whoLiked: ['user1@example.com', 'user2@example.com'] },
		  comments: 5,
		  video: 'https://www.youtube.com/watch?v=l6_03xWXuR4&ab_channel=SuitsOfficial',
		},
		{
		  actor: {
			image: 'https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
			title: 'Jane Smith',
			description: 'Product Manager',
			date: new Date(),
		  },
		  description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
		  sharedImg: 'https://images.pexels.com/photos/346529/pexels-photo-346529.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
		  likes: { count: 15, whoLiked: ['user3@example.com', 'user4@example.com'] },
		  comments: 8,
		  video: 'https://www.youtube.com/watch?v=6z2b4qmSxeQ&ab_channel=TED',
		},
		{
		  actor: {
			image: 'https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
			title: 'Alice Johnson',
			description: 'Data Scientist',
			date: new Date(),
		  },
		  description: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
		  sharedImg: 'https://images.pexels.com/photos/147411/italy-mountains-dawn-daybreak-147411.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
		  likes: { count: 20, whoLiked: ['user5@example.com', 'user6@example.com'] },
		  comments: 12,
		  video: 'https://www.youtube.com/watch?v=-qph0r2WxPM&ab_channel=GoogleDevelopers',
		},
		{
		  actor: {
			image: 'https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
			title: 'Michael Brown',
			description: 'Graphic Designer',
			date: new Date(),
		  },
		  description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
		  sharedImg: 'https://images.pexels.com/photos/247599/pexels-photo-247599.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
		  likes: { count: 18, whoLiked: ['user7@example.com', 'user8@example.com'] },
		  comments: 10,
		  video: 'https://www.youtube.com/watch?v=lFcSrYw-ARY&ab_channel=AdobeCreativeCloud',
		},
	  ];
	  
  
	return (
	  <div>
		{articles.length > 0 &&
		  articles.map((article, key) => (
			<Article key={key}>
			  <SharedActor>
				<a>
				  <img src={article.actor.image} alt="" />
				  <div>
					<span>{article.actor.title}</span>
					<span>{article.actor.description}</span>
					<span>{article.actor.date.toLocaleDateString()}</span>
				  </div>
				</a>
				<button>
				  <img src="/images/ellipses.svg" alt="" />
				</button>
			  </SharedActor>
			  <Description>{article.description}</Description>
			  <SharedImage>
				<a>
				  {article.sharedImg ? (
					<img src={article.sharedImg} alt="" />
				  ) : (
					<ReactPlayer width="100%" url={article.video} />
				  )}
				</a>
			  </SharedImage>
			  <SocialCount>
				{article.likes.count > 0 && (
				  <>
					<li>
					  <button>
						<img src="https://static-exp1.licdn.com/sc/h/d310t2g24pvdy4pt1jkedo4yb" alt="" />
						<span>{article.likes.count}</span>
					  </button>
					</li>
					<li>
					  <a>{article.comments} comments (currently not working)</a>
					</li>
				  </>
				)}
			  </SocialCount>
			  <SocialActions>
				<button>
				  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(0, 0, 0, 0.6)" width="24" height="24" focusable="false">
					<path d="M19.46 11l-3.91-3.91a7 7 0 01-1.69-2.74l-.49-1.47A2.76 2.76 0 0010.76 1 2.75 2.75 0 008 3.74v1.12a9.19 9.19 0 00.46 2.85L8.89 9H4.12A2.12 2.12 0 002 11.12a2.16 2.16 0 00.92 1.76A2.11 2.11 0 002 14.62a2.14 2.14 0 001.28 2 2 2 0 00-.28 1 2.12 2.12 0 002 2.12v.14A2.12 2.12 0 007.12 22h7.49a8.08 8.08 0 003.58-.84l.31-.16H21V11zM19 19h-1l-.73.37a6.14 6.14 0 01-2.69.63H7.72a1 1 0 01-1-.72l-.25-.87-.85-.41A1 1 0 015 17l.17-1-.76-.74A1 1 0 014.27 14l.66-1.09-.73-1.1a.49.49 0 01.08-.7.48.48 0 01.34-.11h7.05l-1.31-3.92A7 7 0 0110 4.86V3.75a.77.77 0 01.75-.75.75.75 0 01.71.51L12 5a9 9 0 002.13 3.5l4.5 4.5H19z"></path>
				  </svg>
				  <span>Like</span>
				</button>
				<button>
				  <img src="/images/comment-icon.svg" alt="" />
				  <span>Comment</span>
				</button>
				<button>
				  <img src="/images/share-icon.svg" alt="" />
				  <span>Share</span>
				</button>
				<button>
				  <img src="/images/send-icon.svg" alt="" />
				  <span>Send</span>
				</button>
			  </SocialActions>
			</Article>
		  ))}
	  </div>
	);
  };

function Main(props) {
	// const [showModal, setShowModal] = useState("close");

	// useEffect(() => {
	// 	props.getArticles();
	// }, []);

	// const clickHandler = (event) => {
	// 	event.preventDefault();
	// 	if (event.target !== event.currentTarget) {
	// 		return;
	// 	}
	// 	switch (showModal) {
	// 		case "open":
	// 			setShowModal("close");
	// 			break;
	// 		case "close":
	// 			setShowModal("open");
	// 			break;
	// 		default:
	// 			setShowModal("close");
	// 			break;
	// 	}
	// };

	// function likeHandler(event, postIndex, id) {
	// 	event.preventDefault();
	// 	let currentLikes = props.articles[postIndex].likes.count;
	// 	let whoLiked = props.articles[postIndex].likes.whoLiked;
	// 	let user = props.user.email;
	// 	let userIndex = whoLiked.indexOf(user);

	// 	if (userIndex >= 0) {
	// 		currentLikes--;
	// 		whoLiked.splice(userIndex, 1);
	// 	} else if (userIndex === -1) {
	// 		currentLikes++;
	// 		whoLiked.push(user);
	// 	}

	// 	const payload = {
	// 		update: {
	// 			likes: {
	// 				count: currentLikes,
	// 				whoLiked: whoLiked,
	// 			},
	// 		},
	// 		id: id,
	// 	};

	// 	props.likeHandler(payload);
	// }

	return (
		<Container>
			<ShareBox>
				<div>
					<img src="/images/user.svg" alt="" />
					<button>
						Start a post
					</button>
				</div>
				<div>
					<button>
						<img src="/images/photo-icon.svg" alt="" />
						<span>Photo</span>
					</button>
					<button>
						<img src="/images/video-icon.svg" alt="" />
						<span>Video</span>
					</button>
					<button>
						<img src="/images/event-icon.svg" alt="" />
						<span>Event</span>
					</button>
					<button>
						<img src="/images/article-icon.svg" alt="" />
						<span>Write article</span>
					</button>
				</div>
			</ShareBox>
			<Content />
			{/* <Content />
			<Content /> */}

			
		</Container>
	);
}


export default Main;



