import React from 'react'

const Pricing = () => {
  return (
    <div className='w-full h-auto'>
        <section class="relative py-16 mx-auto px-6 w-[90vw] h-full">
            
            <div class="grid grid-cols-1 pb-8 text-center">
                <h3
                class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold"
                >
                Security. Privacy. Freedom. For everyone.
                </h3>

                <p class="text-slate-400 max-w-xl mx-auto">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat.
                </p>
            </div>


            <div class="grid grid-cols-1">
                <div class="mt-6">
                <div>
                    <div class="grid lg:grid-cols-4 md:grid-cols-2 gap-[30px]">
                    <div
                        class="group border-b-[3px] border-gray-200 p-6 py-8 hover:border-orange-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow rounded-md bg-gray-50 hover:bg-white h-fit"
                    >
                        <h6 class="font-bold uppercase mb-5 text-orange-600">Free</h6>

                        <div class="flex mb-5">
                        <span class="text-xl font-semibold">$</span>
                        <span class="text-4xl font-semibold mb-0">0</span>
                        <span class="text-xl font-semibold self-end mb-1">/mo</span>
                        </div>

                        <ul class="list-none text-slate-400">
                        <li class="mb-1 flex items-center">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Full Access
                        </li>
                        <li class="mb-1 flex items-center">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Source Files
                        </li>
                        </ul>
                        <p
                        class=" cursor-pointer py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-orange-600 border-orange-600 text-white rounded-md mt-5"
                        >Signup</p>
                    </div>

                    <div
                        class="group border-b-[3px] border-gray-200 p-6 py-8 hover:border-orange-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow rounded-md bg-gray-50 hover:bg-white h-fit"
                    >
                        <h6 class="font-bold uppercase mb-5 text-orange-600">
                        Starter
                        </h6>

                        <div class="flex mb-5">
                        <span class="text-xl font-semibold">$</span>
                        <span class="price text-4xl font-semibold mb-0">9</span>
                        <span class="text-xl font-semibold self-end mb-1">/mo</span>
                        </div>

                        <ul class="list-none text-slate-400">
                        <li class="mb-1 flex items-center">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Full Access
                        </li>
                        <li class="mb-1 flex items-center">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Source Files
                        </li>
                        <li class="mb-1 flex items-center">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Enhanced Security
                        </li>
                        </ul>
                        <p
                        class="cursor-pointer py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-orange-600 border-orange-600 text-white rounded-md mt-5"
                        >Try it Now</p>
                    </div>

                    <div
                        class="group border-b-[3px] border-orange-600 p-6 py-8 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow rounded-md bg-white h-fit"
                    >
                        <span
                        class="absolute -right-11 -top-[10px] rotate-[45deg] w-32 h-16 pt-4 px-2 pb-0 flex items-center justify-center text-white bg-yellow-500 font-semibold text-lg"
                        >popular</span
                        >
                        <h6 class="font-bold uppercase mb-5 text-orange-600">
                        Business
                        </h6>

                        <div class="flex mb-5">
                        <span class="text-xl font-semibold">$</span>
                        <span class="price text-4xl font-semibold mb-0">74</span>
                        <span class="text-xl font-semibold self-end mb-1">/mo</span>
                        </div>

                        <ul class="list-none text-slate-400">
                        <li class="mb-1 flex items-center">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Full Access
                        </li>
                        <li class="mb-1 flex items-center">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Source Files
                        </li>
                        <li class="mb-1 flex items-center">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Free Appointments
                        </li>
                        <li class="mb-1 flex items-center">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Enhanced Security
                        </li>
                        </ul>
                        <p
                        class="cursor-pointer py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-orange-600 border-orange-600 text-white rounded-md mt-5"
                        >Try it Now</p>
                    </div>

                    <div
                        class="group border-b-[3px] border-gray-200 p-6 py-8 hover:border-orange-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow rounded-md bg-gray-50 hover:bg-white h-fit"
                    >
                        <h6 class="font-bold uppercase mb-5 text-orange-600">
                        Professional
                        </h6>

                        <div class="flex mb-5">
                        <span class="text-xl font-semibold">$</span>
                        <span class="price text-4xl font-semibold mb-0">99</span>
                        <span class="text-xl font-semibold self-end mb-1">/mo</span>
                        </div>

                        <ul class="list-none text-slate-400">
                        <li class="mb-1 flex items-center">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Full Access
                        </li>
                        <li class="mb-1 flex items-center">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Source Files
                        </li>
                        <li class="mb-1 flex items-center">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Free Appointments
                        </li>
                        <li class="mb-1 flex items-center">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Enhanced Security
                        </li>
                        <li class="mb-1 flex items-center">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Free Installment
                        </li>
                        </ul>
                        <p
                        class="cursor-pointer py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-orange-600 border-orange-600 text-white rounded-md mt-5"
                        >Try it Now</p>
                    </div>
                    </div>
    
                </div>

                <div
                    class="hidden"
                    id="start-year"
                    role="tabpanel"
                    aria-labelledby="start-year-tab"
                >
                    <div class="grid lg:grid-cols-4 md:grid-cols-2 mt-8 gap-[30px]">
                    <div
                        class="group border-b-[3px] border-gray-200 p-6 py-8 hover:border-orange-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow rounded-md bg-gray-50 hover:bg-white h-fit"
                    >
                        <h6 class="font-bold uppercase mb-5 text-orange-600">Free</h6>

                        <div class="flex mb-5">
                        <span class="text-xl font-semibold">$</span>
                        <span class="price text-4xl font-semibold mb-0">0</span>
                        <span class="text-xl font-semibold self-end mb-1">/mo</span>
                        </div>

                        <ul class="list-none text-slate-400">
                        <li class="mb-1 flex items-center">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Full Access
                        </li>
                        <li class="mb-1 flex items-center">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Source Files
                        </li>
                        </ul>
                        <p
                        class="cursor-pointer py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-orange-600 border-orange-600 text-white rounded-md mt-5"
                        >Signup</p>
                    </div>

                    <div
                        class="group border-b-[3px] border-gray-200 p-6 py-8 hover:border-orange-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow rounded-md bg-gray-50 hover:bg-white h-fit"
                    >
                        <h6 class="font-bold uppercase mb-5 text-orange-600">
                        Starter
                        </h6>

                        <div class="flex mb-5">
                        <span class="text-xl font-semibold">$</span>
                        <span class="price text-4xl font-semibold mb-0">39</span>
                        <span class="text-xl font-semibold self-end mb-1">/mo</span>
                        </div>

                        <ul class="list-none text-slate-400">
                        <li class="mb-1 flex">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Full Access
                        </li>
                        <li class="mb-1 flex">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Source Files
                        </li>
                        <li class="mb-1 flex">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Enhanced Security
                        </li>
                        </ul>
                        <p
                        class="cursor-pointer py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-orange-600 border-orange-600 text-white rounded-md mt-5"
                        >Buy Now</p>
                    </div>

                    <div
                        class="group border-b-[3px] border-orange-600 p-6 py-8 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow rounded-md bg-white h-fit"
                    >
                        <span
                        class="absolute -right-11 -top-[10px] rotate-[45deg] w-32 h-16 pt-4 px-2 pb-0 flex items-center justify-center text-white bg-warning-500 font-semibold text-lg"
                        >popular</span
                        >
                        <h6 class="font-bold uppercase mb-5 text-orange-600">
                        Business
                        </h6>

                        <div class="flex mb-5">
                        <span class="text-xl font-semibold">$</span>
                        <span class="price text-4xl font-semibold mb-0">274</span>
                        <span class="text-xl font-semibold self-end mb-1">/mo</span>
                        </div>

                        <ul class="list-none text-slate-400">
                        <li class="mb-1 flex">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Full Access
                        </li>
                        <li class="mb-1 flex">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Source Files
                        </li>
                        <li class="mb-1 flex">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Free Appointments
                        </li>
                        <li class="mb-1 flex">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Enhanced Security
                        </li>
                        </ul>
                        <p
                        class="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-orange-600 border-orange-600 text-white rounded-md mt-5"
                        >Try it Now</p>
                    </div>

                    <div
                        class="group border-b-[3px] border-gray-200 p-6 py-8 hover:border-orange-600 transition-all duration-500 ease-in-out hover:scale-105 relative overflow-hidden shadow rounded-md bg-gray-50 hover:bg-white h-fit"
                    >
                        <h6 class="font-bold uppercase mb-5 text-orange-600">
                        Professional
                        </h6>

                        <div class="flex mb-5">
                        <span class="text-xl font-semibold">$</span>
                        <span class="price text-4xl font-semibold mb-0">599</span>
                        <span class="text-xl font-semibold self-end mb-1">/mo</span>
                        </div>

                        <ul class="list-none text-slate-400">
                        <li class="mb-1 flex">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Full Access
                        </li>
                        <li class="mb-1 flex">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Source Files
                        </li>
                        <li class="mb-1 flex">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Free Appointments
                        </li>
                        <li class="mb-1 flex">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Enhanced Security
                        </li>
                        <li class="mb-1 flex">
                            <svg
                            class="w-5 h-5 stroke-purple-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                opacity=".1"
                            />
                            <path
                                d="m21 12c0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9 0-4.9706 4.0294-9 9-9 4.9706 0 9 4.0294 9 9z"
                                stroke-width="2"
                            />
                            <path
                                d="m9 12 1.6828 1.6828v0c0.1752 0.1752 0.4592 0.1752 0.6344 0v0l3.6828-3.6828"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            </svg>
                            Free Installment
                        </li>
                        </ul>
                        <p
                        class="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-orange-600 border-orange-600 text-white rounded-md mt-5"
                        >Try it Now</p>
                    </div>
                    </div>

                </div>
                </div>
            </div>
        
        </section>

    </div>
  )
}

export default Pricing