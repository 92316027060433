const data = [
  {
    name: "National Merit Scholarship",
    logo: "https://www.nationalmerit.org/s/1758/images/logo.png",
    description: "Scholarship for meritorious students at the national level.",
    eligibility: "Class 10th and 12th toppers",
    awardingAuthority: "Ministry of Education, Government of India",
    link: "https://example.com/national-merit-scholarship",
    applicationDeadline: "30th November 2023",
    benefits: "Rs. 10,000 per annum",
    state: "National",
    ageCriteria: "16-17",
  },
  {
    name: "National Means-Cum-Merit Scholarship",
    logo: "https://dsel.education.gov.in/themes/nexus/assets/images/nmmss2.jpg",
    description: "Scholarship for economically weaker students.",
    eligibility:
      "Class 8th toppers from economically disadvantaged backgrounds",
    awardingAuthority: "Ministry of Education, Government of India",
    link: "https://example.com/national-means-scholarship",
    applicationDeadline: "15th September 2023",
    benefits: "Rs. 12,000 per annum",
    state: "National",
    ageCriteria: "14",
  },
  {
    name: "National Scholarship for Persons with Disabilities",
    logo: "https://higheredn.delhi.gov.in/sites/default/files/logo/emblem-dark-logo_4.png",
    description: "Scholarship for students with disabilities.",
    eligibility: "Students with 40% or more disability",
    awardingAuthority:
      "Department of Empowerment of Persons with Disabilities, Government of India",
    link: "https://example.com/disabilities-scholarship",
    applicationDeadline: "15th October 2023",
    benefits: "Varies by category",
    state: "National",
    ageCriteria: "disability",
  },
  {
    name: "Central Sector Scholarship Scheme for College and University Students",
    logo: "https://buddy4study.s3.ap-southeast-1.amazonaws.com/article/wp-content/uploads/2022/08/06155611/Central-Sector-Scholarship-%E2%80%93-A-Scholarship-Scheme-for-College-and-University-Students.jpg",
    description: "Scholarship for undergraduate students.",
    eligibility:
      "Class 12th passed students pursuing regular college/university courses",
    awardingAuthority: "Ministry of Education, Government of India",
    link: "https://example.com/central-sector-scholarship",
    applicationDeadline: "30th September 2023",
    benefits: "Rs. 10,000 to Rs. 20,000 per annum",
    state: "National",
    ageCriteria: "Delhi",
  },
  {
    state: "Delhi",
    name: "Delhi Merit Scholarship",
    logo: "https://www.latestlaws.com/media/2019/02/600X314_201607290729064474_Delhi-govt-asks-DANICS-officer-to-join-duty_SECVPF.jpg",
    description: "Scholarship for meritorious students in Delhi.",
    eligibility: "Class 12th toppers from Delhi schools",
    awardingAuthority: "Government of Delhi",
    link: "https://example.com/delhi-merit-scholarship",
    applicationDeadline: "31st December 2023",
    benefits: "Rs. 5,000 per annum",
    ageCriteria: "Delhi",
  },
  {
    state: "Delhi",
    name: "Delhi Minority Scholarship",
    logo: "https://scholarshiparena.in/wp-content/uploads/2021/10/MERIT-SCHOLARSHIP-FOR-MINORITIES-min-1024x576.png",
    description: "Scholarship for minority community students in Delhi.",
    eligibility: "Minority community students pursuing higher education",
    awardingAuthority: "Government of Delhi",
    link: "https://example.com/delhi-minority-scholarship",
    applicationDeadline: "15th October 2023",
    benefits: "Varies by category",
    ageCriteria: "minority",
  },
  {
    state: "Tamil Nadu",
    name: "Tamil Nadu Government Scholarship",
    logo: "https://www.nationalmerit.org/s/1758/images/logo.png",
    description: "Scholarship for students in Tamil Nadu.",
    eligibility: "Students pursuing various courses in Tamil Nadu",
    awardingAuthority: "Government of Tamil Nadu",
    link: "https://example.com/tamil-nadu-scholarship",
    applicationDeadline: "Varies by category",
    benefits: "Varies by category",
    ageCriteria: "Tamil Nadu",
  },
  {
    state: "Karnataka",
    name: "Karnataka ePASS (Electronic Payment and Application System of Scholarships)",
    logo: "https://www.nationalmerit.org/s/1758/images/logo.png",
    description: "Scholarship for students of Karnataka.",
    eligibility: "Students from Karnataka pursuing various courses",
    awardingAuthority: "Government of Karnataka",
    link: "https://example.com/karnataka-epass",
    applicationDeadline: "Varies by category",
    benefits: "Varies by category",
    ageCriteria: "Karnataka",
  },
  {
    state: "Maharashtra",
    name: "Maharashtra State Scholarship",
    logo: "https://www.nationalmerit.org/s/1758/images/logo.png",
    description: "Scholarship for students in Maharashtra.",
    eligibility: "Students pursuing education in Maharashtra",
    awardingAuthority: "Government of Maharashtra",
    link: "https://example.com/maharashtra-state-scholarship",
    applicationDeadline: "28th February 2024",
    benefits: "Varies by category",
    ageCriteria: "Maharashtra",
  },
];

export default data;
